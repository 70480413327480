import axios from 'axios';
import { toast } from 'react-toastify'; // Make sure to install react-toastify or use any other notification library



// endpoints that doesnt require token to be passed in headers
const noAuthEndpoints = [
    process.env.REACT_APP_DEV_LOGIN_ENDPOINT,
    process.env.REACT_APP_GET_SUBSCRIPTION_PLANS_ENDPOINT
];

// endpoints that have some other redirection mapping other then /login
const rawCustomRedirectMapping = [
    [process.env.REACT_APP_DEV_VERIFY_ENDPOINT , '/']
]

// dev endpoints required for handeling with devToken not authToken which is default
const rawDevEndpoints = [
  process.env.REACT_APP_DEV_VERIFY_ENDPOINT
]

// endpoints that need redirection on 403 or permission denied
const rawRedirectionEndpoints = [
  process.env.REACT_APP_DEV_VERIFY_ENDPOINT
]


let customRedirectMapping = rawCustomRedirectMapping.map(([url, redirect]) => {
  if (url.split(":").length > 1  ){
    url = `${process.env.REACT_APP_API_BASE_URL}${url}`
  }
  else{
    url= `${process.env.REACT_APP_API_BASE_URL}/${url}`
  }

  return {url , redirect}
});

let devEndpoints = rawDevEndpoints.map((url)=>{
  if (url.split(":").length > 1  ){
    url = `${process.env.REACT_APP_API_BASE_URL}${url}`
  }
  else{
    url= `${process.env.REACT_APP_API_BASE_URL}/${url}`
  }
  return url;
})


let redirectionEndpoints = rawRedirectionEndpoints.map((url)=>{
  if (url.split(":").length > 1  ){
    url = `${process.env.REACT_APP_API_BASE_URL}${url}`
  }
  else{
    url= `${process.env.REACT_APP_API_BASE_URL}/${url}`
  }
  return url;
})



// Convert the array of objects to a single object
customRedirectMapping = customRedirectMapping.reduce((acc, { url, redirect }) => {
  acc[url] = redirect;
  return acc;
}, {});


function hasDomain(url) {
  try {
    // Try to create a new URL object
    const parsedUrl = new URL(url);
    // If the URL has a hostname, it means it has a domain
    return !!parsedUrl.hostname;
  } catch (e) {
    // If an error is thrown, it means the URL is relative and doesn't have a domain
    return false;
  }
}



const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 50000,
});

api.interceptors.request.use(
  config => {
    
    // Check if the request URL is in the noAuthEndpoints list
    const requiresAuth = !noAuthEndpoints.some(endpoint => config.url.includes(endpoint));


    console.log('Base:> ' , config.baseURL , "Path:> " , config.url, 'NoAuthPaths:> ', noAuthEndpoints , 'RequiresAuth:> ' , requiresAuth )

    config.timeout = config.headers["X-Time-Out"] || config.timeout;
    if (requiresAuth) {
      const token = config.headers['X-Custom-Token'] || localStorage.getItem('authToken');
      if (token) { config.headers.Authorization = `Bearer ${token}` }
    }


    // only for testing in lcoal as we have baseurls different for different services due to 
    // unavailabity of proxy setup
    if ( config.url.split(":").length > 1 ) {
        config.url = `${config.baseURL}${config.url}`
        config.baseURL = ''; // Don't prepend baseURL if full URL is provided
    }


    console.log('Base:> ' , config.baseURL , "Path:> " , config.url , "TimeOut:> " , config.timeout)

    return config;
  },
  error => Promise.reject(error)
);





// Response interceptor for handling errors (unchanged)
api.interceptors.response.use(
  response => response,
  error => {


    if (axios.isCancel(error)) {
      // Handle canceled requests
      toast.error('Request was canceled.');
    } 
    else if (error.code === 'ECONNABORTED') {
      // Handle request timeout
      toast.error('Request timed out. Please try again later.');
    }


    // Unauthorized
    else if (error.response && error.response.status === 401) {
      localStorage.removeItem( process.env.REACT_APP_AUTH_TOKEN_NAME );
      toast.error('Invalid Credentials.');
    } 


    // Frobidden
    else if (error.response && error.response.status === 403) {
        let fullUrl = error.config?.url;
        if (!hasDomain(fullUrl)){
          if (fullUrl.split(":").length >1 ){ fullUrl= `${process.env.REACT_APP_API_BASE_URL}${fullUrl}` }
          else{ fullUrl= `${process.env.REACT_APP_API_BASE_URL}/${fullUrl}`}          
        }

        toast.error(`${error.response?.data?.message}`);

        if (redirectionEndpoints.includes(fullUrl)){
            let redirect_wait_time = 0;  
            let redirect_url = customRedirectMapping[fullUrl]            
            if(!redirect_url){ redirect_url = "/login" }

            // in case of dev endpoints for development team. to be removed in production
            if (devEndpoints.includes(fullUrl)){ localStorage.removeItem(process.env.REACT_APP_DEV_TOKEN_NAME) }
            else{ localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN_NAME) }

            // Redirect to redirect_url after a brief delay
            setTimeout(() => { window.location.href = redirect_url }, redirect_wait_time); 
        }

      }

    // Bad Request Error
    else if (error.response && error.response.status === 400) {
      console.error('>>>> , Bad Request');
      toast.error(`${error.response?.data?.message}`);
    }

    // Server Error
    else if (error.response && error.response.status === 500) {
      console.error('Server error');
      console.error(error.response)
      toast.error('Server error'); 

    }
    return Promise.reject(error);
  }
);

export default api;
